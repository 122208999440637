import axios from "axios";
import cookies from "react-cookies";
import { ILoginResponse } from "./interfaces";
import cookie from 'react-cookies';
import store, { AClearUser } from "./store";

const hostName = window.location.host;

// export const BASE_URL = hostName === 'tstmefr.elbasyacademy.kz/' || process.env.NODE_ENV !== 'production' ? 'http://tstmebk.elbasyacademy.kz' : 'https://bk.elbasyacademy.kz';

// Тест
// export const BASE_URL = "https://tstmebk.elbasyacademy.kz";

// Бой
export const BASE_URL = 'https://bk.elbasyacademy.kz';

export const API_URL = `${BASE_URL}/api`;

// export const SOCKET_URL = hostName === 'tstmefr.elbasyacademy.kz/' || process.env.NODE_ENV !== 'production' ? 'http://tstmecht.elbasyacademy.kz' : 'https://chat.elbasyacademy.kz'

// Тест
// export const SOCKET_URL = 'http://tstmecht.elbasyacademy.kz';

// Бой
export const SOCKET_URL = 'https://chat.elbasyacademy.kz';
export const SOCKET_API_URL = `${SOCKET_URL}/api`;

const instance = (authorization = true, url: string | null = null) => {
  const axiosInstance = axios.create({
    baseURL: url || API_URL,
  });

  if (authorization) {
    const token = cookies.load("token");

    axiosInstance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  return axiosInstance;
};

export const socketInstance = () => {
  const axiosInstance = axios.create({
    baseURL: SOCKET_API_URL,
  });

  const token = cookies.load("token");

  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Token = `${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export const getProfile = (): Promise<ILoginResponse> => {
  return instance()
    .get("/v1/profile")
    .then((response) => {

      if (response.data.data && response.data.data.status_id === 100) {
        cookie.remove('token', {
          path: '/'
        });

        store.dispatch(AClearUser());

        window.location.reload();
      }

      return response.data.data;
    })
};

export const getContent = (alias:string): Promise<any> => {
  return instance()
    .get(`/v1/pages/view/${alias}`)
    .then((response) => response.data.data);
};

export const getSite = (): Promise<{
    status_id: number,
    roles: number[],
    condition_type: number,
}> => {
    return instance()
        .get(`/v1/site-status`)
        .then((response) => response.data);
};

export default instance;
