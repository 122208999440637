import moment from 'moment';
import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getContent } from '../../api';
import ModalLogin from '../../modals/Login';
import ModalResetPassword from '../../modals/ResetPassword';
import { PATH } from '../../router';
import { ASetLocale, IStore } from '../../store';
import './styles.scss';

interface IProps {
}

const FooterDefault: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const user = useSelector((store: IStore) => store.user);
  const [callcenter, setCallcenter] = useState({
    content_ru: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_kz: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_en: '<a href=\'tel:7170\' class=\'call\'>7170</a>'
  });
  const [callcenterHeader, setCallcenterHeader] = useState({
    content_ru: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_kz: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_en: '<a href=\'tel:7170\' class=\'call\'>7170</a>'
  });
  const [social, setSocial] = useState({
    content_ru: '<a href=\'\'></a>',
    content_kz: '<a href=\'\'></a>',
    content_en: '<a href=\'\'></a>'
  });

  useEffect(() => {
    // @ts-ignore
    $(window).scroll(function () {
      // @ts-ignore
      if ($(this).scrollTop() >= 300) {
        // @ts-ignore
        $('.back-top').fadeIn('fast');
      } else {
        // @ts-ignore
        $('.back-top').fadeOut('fast');
      }
    });
    getContent(`callcenter-footer-block`).then(response => setCallcenter(response));
    getContent(`callcenter-header-block`).then(response => setCallcenterHeader(response));
    getContent(`social-media-menu-block`).then(response => setSocial(response));
  }, []);

  const onKeyPressSearch = (e: any) => {
    e.persist();

    if (e.which === 13 || e.keyCode === 13) {
      // @ts-ignore
      $('.modal-form-blk').fadeOut('fast');
      // @ts-ignore
      $('.overlay').fadeOut('fast');

      history.push(PATH.search_results + `?q=${$(e.target).val()}`);
    }
  };
  const dispatch = useDispatch();
  const {locale, participantsCount} = useSelector((state: IStore) => ({
    locale: state.locale,
    participantsCount: state.participantsCount
  }));

  const setLocale = (locale: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    cookie.save('locale', locale, {path: '/'});

    dispatch(ASetLocale(locale));
  };

  return (
    <React.Fragment>
      <footer className="footer-default">
        <div className="cnt-blk">
          <div className="row">
            <div className="col-sm-3">
              <div className="copy">
                <div className="e">
                  &copy; {moment().year()} {t('copyright')}
                </div>
                <div className="e">
                  {t('join_us_in_socials')}:
                </div>
                {/* @ts-ignore */}
                <div className="e social" dangerouslySetInnerHTML={{__html: social[`content_${locale}`]}} />
              </div>
            </div>
            <div className="col-sm-9">
              <h6 className="bebas">{t('extra_menu')}</h6>
              <nav className="row">
                <div className="col-sm-4">
                  <ul>
                    <li>
                      <Link to={PATH.home}>{t('home')}</Link>
                    </li>
                    {/* <li>
                      <a href="about.html">О проекте</a>
                    </li> */}
                    <li>
                      <Link to={PATH.program}>{t('program')}</Link>
                    </li>
                    <li>
                      <Link to={PATH.news}>{t('news')}</Link>
                    </li>
                    <li>
                      <Link to={PATH.smi}>{t('mass_media_about_us')}</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul>
                    {/* <li>ГЕОГРАФИЯ ПРОЕКТА

                      <a href="#">Победители</a>
                    </li> */}
                    <li>
                      <Link to={PATH.map}>{t('geography_of_the_project')}</Link>
                    </li>
                    <li>
                      <Link to={PATH.media}>{t('media')}</Link>
                    </li>
                    <li>
                      <Link to={PATH.contact}>{t('contacts')}</Link>
                    </li>
                    {/* <li>
                      <Link to={PATH.vidioguide}>Видео инструкция</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul>
                    <li>
                      <Link to={PATH.moderation}>{t('moderation_policy')}</Link>
                    </li>
                    <li>
                      <Link to={PATH.termsofuse}>{t('terms_of_use')}</Link>
                    </li>
                    {/* <li>
                      <a href="#">Партнеры</a>
                    </li> */}
                    <li>
                      <Link to={user.authenticated ? PATH.cabinetFAQ : PATH.faq}>
                        {user?.authenticated ? t('faq_and_video_lessons') : t('faq')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="row wrap">
            <div className="col-sm-6 text-left">
              <div className="lg" />
            </div>
            <div className="col-sm-6 text-right call"
              //@ts-ignore
                 dangerouslySetInnerHTML={{__html: callcenter[`content_${locale}`]}} />
          </div>
        </div>
      </footer>
      <a className="back-top" />
      <div className="overlay" />
      <ModalLogin />
      {/*@ts-ignore*/}
      <ModalResetPassword />
      <div className="modal-form-blk search-form transparent" id="search">
        <div className="in-blk">
          <a href="#" className="close-modal" />
          <div className="form-simple">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onKeyPress={onKeyPressSearch}
                    type="text"
                    placeholder={t('who_can_participate')}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-form-blk media-modal" id="media-show">
        <div className="in-blk">
          <a href="#" className="close-modal" />
          <div className="row stat-info-blk">
            <div className="col-sm-4 text-left">
              <div className="views e">556</div>
            </div>
            <div className="col-sm-8 text-right">
              <div className="share-blk">
                <span>{t('share_with_friends')}:</span>
                <a href="#" className="inst" />
                <a href="#" className="fb" />
                <a href="#" className="yt" />
                <a href="#" className="vk" />
                <a href="#" className="tw" />
              </div>
            </div>
          </div>
          <div className="media-load">
            <div className="img" style={{backgroundImage: 'url(../images/test_media_img_2.jpg)'}}>

            </div>
            <h2 className="title">{t("testTitle")}</h2>
          </div>
        </div>
      </div>
      <div className="mobile-menu nv">
        <div className="hd">
          <div className="tbld">
            <div className="cl w-10">
              <div className="lang-select">
                <div className="dropdown show">
                  <span className="current" data-toggle="dropdown" aria-haspopup="false"
                        aria-expanded="false">{locale}</span>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {['ru', 'kz', "en"].map((item, index) => {
                      if (locale && item.toLowerCase() === locale.toLowerCase()) {
                        return null;
                      }

                      return (
                        <a key={index}
                           onClick={setLocale(item)}
                           className="dropdown-item"
                           href="#">
                          {item.toUpperCase()}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="links-blk cl w-90 text-right">
              <div className="counter-blk">
                <CountUp start={0} end={participantsCount} duration={2} delay={0}>
                  {({countUpRef}) => (
                    <span ref={countUpRef} className="char bebas" />
                  )}
                </CountUp>
                {t('already_applied')}
              </div>
              <a href="#search" data-target="search" className="srch show-modal" />
              <a href="#" className="gls bvi-open" />
              <div //@ts-ignore
                 dangerouslySetInnerHTML={{__html: callcenterHeader[`content_${locale}`]}} ></div>
            </div>
          </div>
        </div>
        <div className="bd">
          <ul>
            <li className="active"><Link to={PATH.home} className="color-1">{t('about_project')}</Link></li>
            <li><Link to={PATH.program} className="color-2">{t('program')}</Link></li>
            <li><Link to={PATH.rules} className="color-3">{t('rules')}</Link></li>
            <li><Link to={PATH.news} className="color-4">{t('news')}</Link></li>
            <li><Link to={PATH.parents} className="color-6">{t('for_parents')}</Link></li>
            <li><Link to={user.authenticated ? PATH.cabinetFAQ : PATH.faq} className="color-5">
              {user.authenticated ? t('faq_and_video_lessons') : t('faq')}
            </Link></li>
          </ul>
          {/* @ts-ignore */}
          <div className="e social" dangerouslySetInnerHTML={{__html: social[`content_${locale}`]}} />
        </div>
        <div className="ft">
          <h6 className="bebas">{t('extra_menu')}</h6>
          <nav>
            <ul>
              <li>
                <Link to={PATH.home}>{t('home')}</Link>
              </li>
              <li>
                <Link to={PATH.home}>{t('about_project')}</Link>
              </li>
              <li>
                <Link to={PATH.program}>{t('program')}</Link>
              </li>
              <li>
                <Link to={PATH.news}>{t('news')}</Link>
              </li>
              <li>
                <Link to={PATH.smi}>{t('mass_media_about_us')}</Link>
              </li>
            </ul>
            <ul>
              {/* <li>
                                        <Link to={PATH.program}>Победители</a>
                                    </li> */}
              <li>
                <Link to={PATH.map}>{t('map')}</Link>
              </li>
              <li>
                <Link to={PATH.media}>{t('media')}</Link>
              </li>
              <li>
                <Link to={PATH.contact}>{t('contacts')}</Link>
              </li>
              <li>
                <Link to={PATH.vidioguide}>{t('video_instruction')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to={PATH.moderation}>{t('moderation_policy')}</Link>
              </li>
              <li>
                <Link to={PATH.termsofuse}>{t('terms_of_use')}</Link>
              </li>
              {/*  <li>
                  <a href="#">Партнеры</a>
              </li> */}
              <li>
                <Link
                  to={user.authenticated ? PATH.cabinetFAQ : PATH.faq}>{user.authenticated ? t('faq_and_video_lessons') : t('faq')}</Link>
              </li>
            </ul>
          </nav>
          <div className="copy">
            &copy; {moment().year()} {t('copyright')}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FooterDefault;
