import React from 'react';
import { SOCKET_URL } from '../../api';
import { downloadFile } from '../../helpers';
import { IChatAttachment } from './api';
import { useTranslation } from 'react-i18next';

interface IProps {
  attachment: IChatAttachment;
}

const MessageDoc: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {attachment} = props;

  const onClickDownloadFile = (attachment: IChatAttachment) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    downloadFile(`${SOCKET_URL}/${attachment.path}/${attachment.filename}`, attachment.filename);
  };

  return (
    <React.Fragment>
      <div className="icon" />
      <div className="desc">
        <a
          href="#"
          className="name"
          onClick={onClickDownloadFile(attachment)}>
          {attachment.filename}
        </a>
        <div className="info">
          <a
            href="#"
            onClick={onClickDownloadFile(attachment)}
            className="chat-download-link" />
          <span>{t("download")}</span>
          {/*<span>Нет данных о размере</span>*/}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageDoc;
