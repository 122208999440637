import React from 'react';
import {useLocation} from "react-router-dom";
import instance from "../../api";
import { useTranslation } from 'react-i18next';

export const EmailConfirm = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const submit = () => {
        const search = location.search;
        if (search.split('=')[0] === '?token' && search.split('=')[1]) {
            return instance(false).get(`/v1/email/${search.split('=')[1]}/confirm`,)
                .catch(() => alert(t("error")));
        }
    }

    const goHome = () => {
        window.location.replace('/');
    };

    return (
        <section className="register-form-section content-blk">
            <div className="cnt-blk">
                <h2 className="page-title">{t("email_confirmation_title")}</h2>
                <form className="form-simple">
                    <div className="row">
                        <div className="col-sm-6 form-responsive-col">
                            <div className="form-section-blk">
                                <div className="hint">
                                    {t("email_confirmation_message")}
                                </div>
                                <div className="row" data-stage="6" id="step-6">
                                    <div className="col-sm-12 text-center">
                                        <a className="btn btn-primary show-modal"
                                           onClick={submit} style={{ color: 'white' }}
                                           data-target="success-register">{t("confirm_button_label")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-form-blk auth-form success-register" id="success-register">
                <div className="in-blk">
                    <a href="#" className="close-modal"></a>
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="txt">
                                <div className="i"></div>
                                <h4 className="header-title">{t("confirmation_message")}</h4>
                                <a className="btn btn-primary" onClick={goHome} style={{color: 'white'}}>{t("back_to_home")}</a>
                            </div>
                        </div>
                        <div className="col-sm-5 img">

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
