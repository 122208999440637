import { isUndefined } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import CountUp from 'react-countup';
// @ts-ignore
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { getContent, getProfile } from '../../api';
import Input from '../../components/Input';
import { getUserRequiredFields, getUserRole, getUserThumb, USER_ROLES } from '../../helpers';
import { INotification } from '../../interfaces';
import ModalTaskDetail from '../../modals/TaskDetails';
import { getMember } from '../../pages/Cabinet/pages/Notifications/api';
import { updateProfile } from '../../pages/Cabinet/pages/ProfileInfoEdit/api';
import { PATH } from '../../router';
import { AClearUser, ASetLocale, ASetNotifications, ASetVision, IStore } from '../../store';
import { getMemberTask, getNotifications, PostResponse, setNotificationAsViewed } from './api';

interface IProps {
  locale?: string;
  vision?: string;
  participantsCount?: number;
  notifications?: INotification[];
}

interface IState {
  response: string;
  member_id: string;
}

const initialState: IState = {
  response: '',
  member_id: ''
};

interface IErrors {
  [key: string]: string | null;
}

const initialErrors: IErrors = {};

const HeaderDefault: React.FC<IProps> = (props) => {
  const {t, i18n} = useTranslation();
  const {
    user,
    locale,
    vision,
    participantsCount,
    notifications
  } = useSelector((store: IStore) => ({
    vision: store.vision,
    locale: store.locale,
    user: store.user,
    participantsCount: store.participantsCount,
    notifications: store.notifications
  }));
  const [errors, setErrors] = useState(initialErrors);
  const [state, setState] = useState(initialState);
  const [isResponse, setIsResponse] = useState(false);
  const [task, setTask] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [callcenter, setCallcenter] = useState({
    content_ru: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_kz: '<a href=\'tel:7170\' class=\'call\'>7170</a>',
    content_en: '<a href=\'tel:7170\' class=\'call\'>7170</a>'
  });

  const newNotifications = notifications!.filter((item) => item.read_at === null);
  const notificationsLength = newNotifications.length;

  useEffect(() => {
    getContent(`callcenter-header-block`).then(response => setCallcenter(response));
  }, []);

  useEffect(() => {
    if(!locale) {
      dispatch(ASetLocale('kz'));
    }
    
    if (user.authenticated) {
      setIsResponse(user?.user_role?.slug === 'mentor');
      getNotifications()
        .then((response) => {
          dispatch(ASetNotifications(response));
        })
        .catch(() => {
          dispatch(ASetNotifications([]));
        });
    }
  }, [user.authenticated]);

  useEffect(() => {
    if (vision) {
      // @ts-ignore
      $('head').append(
        '<link id=\'vision\' rel=\'stylesheet\' type=\'text/css\' href=\'/css/biblack.css\'/>'
      );
    } else {
      // @ts-ignore
      $('#vision').remove();
    }

  }, [vision]);

  const setLocale = (locale: string) => (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.persist();
    e.preventDefault();

    if (user.authenticated) {
      getProfile()
        .then((response) => {
          updateProfile({
            ...getUserRequiredFields(response),
            locale
          })
            .then(() => {
            })
            .catch(() => {
            });
        })
        .catch(() => {
        });
    }

    cookie.save('locale', locale, {
      path: '/'
    });

    dispatch(ASetLocale(locale));

    i18n.changeLanguage(locale);
  };

  const setVision = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.persist();
    e.preventDefault();

    if (!vision) {
      cookie.save('vision', 'true', {
        path: '/'
      });

      dispatch(ASetVision(true));
    } else {
      cookie.remove('vision', {
        path: '/'
      });

      dispatch(ASetVision(false));
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist();

    const {name, value} = e.target;

    if (value.length > 0 && !!errors[name]) {
      const tempErrors = {...errors};

      tempErrors[name] = null;
      delete tempErrors[name];

      setErrors({...tempErrors});
    }

    setState({
      ...state,
      [name]: value
    });
  };

  const onClickLogout = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    cookie.remove('token', {
      path: '/'
    });

    dispatch(AClearUser());

    history.push(PATH.home);
  };

  const onOpenResponse = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();
    var id = $(e.currentTarget).data('id');
    var notify: any = newNotifications.find((x) => x.id === id);
    //console.log(notify);
    setState({member_id: notify.data.data.member_id, response: ''});
    $('#response_mentor').show();
  };

  const validationObject = {
    // @ts-ignore
    response: yup.string().ensure().required(t('required_field'))
  };
  const validationSchema = yup.object().shape(validationObject);

  const onResponse = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.persist();
    e.preventDefault();
    validationSchema
      .validate(state, {
        abortEarly: false
      })
      .then(() => {
        setErrors({});

        PostResponse(state.member_id, {message: state.response})
          .then((data) => {
            window.location.reload();
          })
          .catch((error) => {
            // @ts-ignore
            const currentErrors: IErrors = {};
            if (error.response.status !== 500) {
              if (error.response) {
                if (error.response.data.errors.message) {
                  // @ts-ignore
                  currentErrors.response = t('required_field');
                }
              }
            }
            setErrors(currentErrors);
          });
      })
      .catch((yupErrors) => {
        const errors = {};

        yupErrors.inner.forEach((item: ValidationError) => {
          // @ts-ignore
          errors[item.path] = item.message;
        });

        setErrors(errors);
      });
  };

  const onClickCloseNotifications = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.persist();
    e.preventDefault();

    $('.notify .notify-list').slideUp(100);
  };

  const onClickNotifications = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (notificationsLength > 0) {
      $(e.target).find('.notify-list').slideDown(100);
    } else {
      history.push(PATH.cabinetNotifications);
    }
  };

  const onClickNotification = (id: string) => (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.persist();
    e.preventDefault();

    dispatch(
      ASetNotifications(
        notifications!.map((item) => ({
          ...item,
          read_at:
            item.id === id
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : item.read_at
        }))
      )
    );

    setNotificationAsViewed(id)
      .then(() => {
      })
      .catch(() => {
      });
  };

  const onClickShowMember = (note: any) => (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.persist();
    e.preventDefault();

    history.push(PATH.generate(PATH.cabinetProfileInfoNew, note.data.member_id.toString()));
  };

  const onClickShowTask = (note: any) => (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.persist();
    e.preventDefault();
    console.log('note', note);

    if ([USER_ROLES.member.id].indexOf(user.user_role_id) > -1 && !isUndefined(note.data.task_date) && note.data.task_id) {
      //@ts-ignore
      history.push(
        `${PATH.generate(
          PATH.cabinetDiary
        )}?exerciseId=${note.data.task_id}&date=${note.data.task_date}`
      );
    } else if ([USER_ROLES.mentor.id].indexOf(user.user_role_id) > -1 && note.type_id === 16) {
      //@ts-ignore
      history.push(
        `${PATH.generate(
          PATH.cabinetProfileInfoNew, note.data.member_id
        )}`
      );

    } else if ([USER_ROLES.mentor.id].indexOf(user.user_role_id) > -1 && note.type_id === 2) {
      //@ts-ignore
      history.push(
        `${PATH.generate(
          PATH.cabinetProfilePerfomance, note.data.member_id
        )}?tab=tasks`
      );

    } else if ([USER_ROLES.expert.id, USER_ROLES.coordinator.id].indexOf(user.user_role_id) > -1 && note.type_id === 8 && !isUndefined(note.data.member_id)) {
      user.user_role?.slug && getMember(user.user_role?.slug.replace('_', '-'), note.data.member_id).then(response => {
        //@ts-ignore
        history.push(
          `${PATH.generate(
            PATH.cabinetRegistry
          )}?q=${response.email}`
        );
      });
    } else {

      note.data.task_id && getMemberTask(note.data.task_id, getUserRole(user.user_role_id))
        .then((response) => {
          if (response.status_id === 1) {
            const win = window.open(`${PATH.generate(
              PATH.cabinetProfilePerfomance, response.member_id
            )}?page=1&tab=tasks`, "_blank");
            // @ts-ignore
            win.focus();
            return;
          }

          // @ts-ignore
          setTask(response);
          var sWidth = $(window).width();
          var id = 'task-more-1',
            $item = $(`#${id}`),
            mHeight = $item.height();
          // @ts-ignore
          if (sWidth >= 800) {
            // @ts-ignore
            $('#' + id).css({'margin-top': '-' + Math.round(parseInt(mHeight / 2)) + 'px'});
          }
          $item.show();
          // history.push(
          //   `${PATH.generate(
          //     PATH.cabinetProfilePerfomance,
          //     response.member_id
          //   )}?page=1&taskId=${taskId}`
          // )
        })
        .catch((error) => {
          $('.modal-form-blk').fadeOut('fast');
          $('.overlay').fadeOut('fast');
          alert(t("failed"));
        });
    }
  };

  const protocol = window.location.protocol;
  const hostName = window.location.host;

  return (
    <React.Fragment>
      <header>
        <div className="mobile-app-links">
          <div className="tbld">
            <div className="rw">
              <div className="cl w-50">
                <span>{t('use_the_mobile_app')}</span>
              </div>
              <div className="cl w-50">
                {!isIOS && (
                  <a
                    href="https://play.google.com/store/apps/details?id=dev.amsmirnov.medalelbasy&hl=ru"
                    className="gp">
                    {t('download_to_android')}
                  </a>
                )}
                {isIOS && (
                  <a
                    href="https://apps.apple.com/ru/app/elbasy-medali/id1529574666"
                    className="ios">
                    {t('download_to_iphone')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tbld">
          <div className="rw">
            <div className="cl w-5 text-center">
              <Link to={PATH.home}>
                <img src={'../images/burger_icon.svg'} alt="" />
              </Link>
            </div>
            <div className="cl w-40 nowrapper">
              <Link to={PATH.home} className="lg" id="link-home">
                <img src="/images/lg.svg" alt="" />
              </Link>
              <div className="app-links">
                <span>{t('use_the_mobile_app')}</span>
                <a href="https://play.google.com/store/apps/details?id=dev.amsmirnov.medalelbasy&hl=ru"
                   className="gp" target="_blank" />
                <a href="https://apps.apple.com/ru/app/elbasy-medali/id1529574666" className="ios" target="_blank" />
              </div>
            </div>
            <div className="cl w-55 text-right nowrapper">
              {!user.authenticated ? (
                <React.Fragment>
                  <a
                    href="#authorize"
                    data-target="authorize"
                    className="btn btn-default show-modal"
                  >
                    {t('cabinet')}
                  </a>
                  <Link to={PATH.request} className="btn btn-primary">
                    {t('make_a_request')}
                  </Link>
                </React.Fragment>
              ) : (
                <div className="auth-user-blk">
                  <Link to={PATH.cabinetFAQ} className="faq link" />
                  <div className="notify link" onClick={onClickNotifications}>
                    {notificationsLength > 0 && (
                      <span>{user.new_notification_count}</span>
                    )}
                    <div className="notify-list">
                      <div className="in-blk">
                        <a
                          href="#"
                          className="close-notify"
                          onClick={onClickCloseNotifications}
                        />
                        <div className="hd-blk">
                          <div className="row">
                            <div className="col-sm-5">
                              <h2 className="bebas">{t('notifications')}</h2>
                            </div>
                            <div className="col-sm-7">
                              {notificationsLength > 0 && (
                                <div className="btn-blk text-center">
                                  <Link
                                    to={PATH.cabinetNotifications}
                                    className="btn btn-default"
                                  >
                                    {t('show_all_notifications')}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>

                        </div>

                        {newNotifications!
                          .filter((item, index) => index <= 1)
                          .map((item: any, index) => {
                            //console.log({isResponse, type_id: item.data.type_id})
                            return (
                              <React.Fragment key={item.id}>
                                <div
                                  className="elm new"
                                  style={{
                                    position: 'relative'
                                  }}
                                >
                                  <div className="date">
                                    {moment(item.created_at).format(
                                      'DD-MM-YYYY HH:mm'
                                    )}
                                  </div>
                                  <div className="txt">{item.data.message}</div>
                                  {/*<a href="#" className="btn btn-default">Написать ученику</a>*/}
                                  {item.data.data && !!item.data.type_id && item.data.type_id === 16 && (
                                    <div className="btn-blk">
                                      <a
                                        href=""
                                        onClick={onClickShowMember(item.data)}
                                        className="btn-primary btn"
                                      >
                                        {t('show')}
                                      </a>
                                    </div>
                                  )}
                                  {isResponse && item.data.type_id === 1 && (
                                    <div className="btn-blk">
                                      <a
                                        href="#"
                                        className="btn-primary btn"
                                        data-id={item.id}
                                        onClick={onOpenResponse}
                                      >
                                        {t('reply')}
                                      </a>
                                    </div>
                                  )}
                                  {item.data.data && !isUndefined(item.data.data.member_id) && !isUndefined(item.data.type_id) && item.data.type_id !== 1 && item.data.type_id !== 16 && item.data.type_id !== 21 && (
                                    <div className="btn-blk">
                                      <a
                                        href="#task-more-1"
                                        data-target="task-more-1"
                                        onClick={onClickShowTask(
                                          item.data
                                        )}
                                        className="btn-primary btn"
                                      >
                                        {t('show')}
                                      </a>
                                    </div>
                                  )}
                                  {item.data.data && !isUndefined(item.data.data.member_id) && !isUndefined(item.data.type_id) && item.data.type_id === 21 && (
                                    <div className="btn-blk">
                                      <a
                                        href={`${protocol}//${hostName}/cabinet/profile/members/${item.data.data.member_id}/info`}
                                        className="btn-primary btn"
                                      >
                                        {t('go_to_profile')}
                                      </a>
                                    </div>
                                  )}
                                  <a
                                    href="#"
                                    onClick={onClickNotification(item.id)}
                                    style={{
                                      position: 'absolute',
                                      right: 5,
                                      top: 5,
                                      display: 'block',
                                      width: 16,
                                      height: 16,
                                      backgroundImage:
                                        'url(/images/close_icon_black.svg)',
                                      backgroundRepeat: 'no-repeat'
                                    }}
                                  />
                                </div>
                                <div className="wrapper" />
                              </React.Fragment>
                            );
                          })}

                      </div>
                    </div>
                  </div>
                  <div className="user-name link">
                    {user.first_name}
                    <div
                      className="user-avatar"
                      style={{
                        backgroundColor: '#eee',
                        backgroundImage:
                          getUserThumb(user.avatar) ||
                          'url(/images/user_icon.svg)',
                        backgroundSize: getUserThumb(user.avatar)
                          ? 'cover'
                          : '50%',
                        backgroundPosition: 'center'
                      }}
                    />
                    <div className="user-menu">
                      <div className="hd-blk e">
                        <div className="name">
                          {user.last_name} <br /> {user.first_name}
                        </div>
                        <Link to={PATH.cabinetProfileInfoEdit}>
                          {t('go_to_profile')}
                        </Link>
                        <div
                          className="user-avatar"
                          style={{
                            backgroundImage:
                              getUserThumb(user.avatar) ||
                              'url(/images/user_icon.svg)',
                            backgroundColor: '#eee',
                            backgroundSize: getUserThumb(user.avatar)
                              ? 'cover'
                              : '50%',
                            backgroundPosition: 'center'
                          }}
                        />
                      </div>
                      <div className="e">
                        {(function () {
                          if (user) {
                            let path = PATH.cabinet;

                            if (
                              [
                                USER_ROLES.coordinator.id,
                                USER_ROLES.expert.id
                              ].indexOf(user.user_role_id) > -1
                            ) {
                              path = PATH.cabinetRegistry;
                            }

                            return <Link to={path}>{t('cabinet')}</Link>;
                          }
                        })()}
                      </div>
                      <div className="e">
                        {<Link to={PATH.cabinetSettings}>{t('settings')}</Link>}
                      </div>
                      <div className="e exit">
                        <a href="#" onClick={onClickLogout}>
                          {t('logout')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!!participantsCount && (
                <div className="counter-blk">
                  <CountUp
                    start={0}
                    end={participantsCount}
                    duration={1}
                    delay={0}
                  >
                    {({countUpRef}) => (
                      <span ref={countUpRef} className="char bebas" />
                    )}
                  </CountUp>
                  {t('already_applied')}
                </div>
              )}
              <div className="lang-select">
                <div className="dropdown show">
                  <span
                    className="current"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    {locale}
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {['ru', 'kz', 'en'].map((item, index) => {
                      if (locale && item.toLowerCase() === locale!.toLowerCase()) {
                        return null;
                      }

                      return (
                        <a
                          key={index}
                          onClick={setLocale(item)}
                          className="dropdown-item"
                          href="#"
                        >
                          {item.toUpperCase()}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="links-blk">
                <a href="#search" data-target="search" className="srch show-modal" />
                {/* <a href="#" onClick={setVision} className="gls bvi-open" /> */}
                <div className="spaceForEye"></div>
              </div>
              {/* @ts-ignore */}
              {/* <div className="links-blk" dangerouslySetInnerHTML={{__html: callcenter[`content_${locale}`]}} /> */}
              <div className="links-blk"><a className="call" href="tel:88000804848">8 800 080 48 48</a></div>
            </div>
          </div>
        </div>
        <div className="modal-form-blk auth-form" id="response_mentor">
          <div className="in-blk">
            <a href="#" className="close-modal close-black" />
            <div className="row">
              <div className="col-sm-12">
                <form className="form-simple">
                  <h4 className="header-title">{t('write_reply_to_participant')}</h4>
                  <div className="form-group combined">
                    <Input
                      required={true}
                      error={errors.response}
                      label={t('reply')}
                      value={state.response}
                      name="response"
                      onChange={onChange}
                      type="text"
                    />
                  </div>
                  <div className="btn-blk">
                    <button className="btn btn-primary" onClick={onResponse}>
                      {t('reply')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>
      {user && (
        <ModalTaskDetail
          multiple={true}
          modalIdIndex={1}
          exercise={task}
          allowActions={true}
          role={user.user_role_id}
          reloadData={() => {
            setTask(null);
          }}
          locale={locale}
        />
      )}
    </React.Fragment>
  );
};

export default HeaderDefault;
