import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocale } from '../helpers';
import ru from './ru.json';
import kz from './kz.json';
import en from './en.json';

const resources = {
  ru: {
    translation: ru
  },
  kz: {
    translation: kz
  },
  en: {
    translation: en
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getLocale(),
    keySeparator: false,
    //fallbackLng: 'ru',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
